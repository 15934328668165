import {
  Button,
  Flex,
  View,
  Text,
  Dialog,
  Heading,
  Content,
  useDialogContainer,
  ButtonGroup,
  DialogContainer,
  AlertDialog,
  TextArea,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { error } from "@react/react-spectrum/Toast";
import PropTypes from "prop-types";
import { PENDING } from "../../../constants/Status";
import Table from "../../Common/Table";
import { workflowApi } from "../../../api/workflowApi";

export default function ViewWorkflowDetailsDialog({
  details,
  handleApproval,
  handleRejection,
  isManualGrouping,
  isAccountUpdate,
}) {
  const dialog = useDialogContainer();
  const [loadingState, setLoadingState] = useState("");
  const [oldState, setOldState] = useState([]);
  const [newState, setNewState] = useState([]);
  const [colInfo, setColInfo] = useState();
  const [rejectionDialog, setRejectionDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const { authState } = useOktaAuth();

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    workflowApi
      .fetchWorkflowDetails(details.wf_id, authState.accessToken)
      .then((data) => {
        setLoadingState("loading");
        if (isManualGrouping) {
          setOldState(
            data.from_state.account.map((acc) => ({
              ...acc,
              id: `${acc.org_entity_id}:${acc.account_id}`,
            }))
          );
          setNewState(
            data.to_state.account.map((acc) => ({
              ...acc,
              id: `${acc.org_entity_id}:${acc.account_id}`,
            }))
          );
        } else if (isAccountUpdate) {
          setOldState([data.from_state.existing_state]);
          setNewState([data.to_state.new_state]);
        } else {
          setOldState([{ ...data.from_state, id: 1 }]);
          setNewState([{ ...data.to_state, id: 1 }]);
        }

        const dynamicColumns = [];
        let objToIterate;
        if (isManualGrouping) objToIterate = data?.from_state?.account[0];
        else if (isAccountUpdate) objToIterate = data.from_state.existing_state;
        else objToIterate = data.from_state;
        Object.keys(objToIterate).forEach((attr) => {
          dynamicColumns.push({
            uid: attr,
            name:
              attr.charAt(0).toUpperCase() + attr.slice(1).replaceAll("_", " "),
          });
        });
        setColInfo(dynamicColumns);
        setLoadingState("");
      })
      .catch((e) => handleError(e));
  }, []);

  const renderNewStateCell = (colKey, row) => {
    let cellStyle = {};
    if (!isManualGrouping && row[colKey] !== oldState[0][colKey])
      cellStyle = { color: "green" };
    if (
      isManualGrouping &&
      oldState.map((acc) => acc.account_id).includes(row.account_id)
    )
      cellStyle = { color: "green" };
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
      </span>
    );
  };

  const renderOldStateCell = (colKey, row) => {
    let cellStyle = {};
    if (
      isManualGrouping &&
      newState.map((acc) => acc.account_id).includes(row.account_id)
    )
      cellStyle = { color: "red" };
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
      </span>
    );
  };

  return (
    <Dialog width={isAccountUpdate ? "120rem" : "70rem"}>
      <Content>
        <Flex direction="column">
          <View>
            <Heading level={3}>
              Workflow Id:{" "}
              <Text UNSAFE_style={{ fontWeight: "normal" }}>
                {details.wf_id}
              </Text>
            </Heading>
            <Heading level={3}>
              Type:{" "}
              <Text UNSAFE_style={{ fontWeight: "normal" }}>
                {details.wf_type}
              </Text>
            </Heading>
            <Heading level={3}>
              Comments:{" "}
              <Text UNSAFE_style={{ fontWeight: "normal" }}>
                {details.comments}
              </Text>
            </Heading>
          </View>

          {colInfo && (
            <Flex direction="column" gap="size-125">
              <Heading level={4} marginStart="size-300" marginTop="size-300">
                Old State:
              </Heading>
              <Flex
                direction="column"
                marginStart="size-300"
                marginEnd="size-300"
                marginTop="size-10"
                marginBottom="size-300"
                UNSAFE_style={{
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                }}
                UNSAFE_className="stepper-box"
              >
                <Table
                  columns={colInfo}
                  rows={oldState}
                  selectionMode="none"
                  renderCell={renderOldStateCell}
                  density="compact"
                  loadingState={loadingState}
                />
              </Flex>
              <Heading level={4} marginStart="size-300">
                New State:
              </Heading>
              <Flex
                direction="column"
                marginStart="size-300"
                marginEnd="size-300"
                marginTop="size-10"
                marginBottom="size-300"
                UNSAFE_style={{
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                }}
                UNSAFE_className="stepper-box"
              >
                <Table
                  columns={colInfo}
                  rows={newState}
                  selectionMode="none"
                  renderCell={renderNewStateCell}
                  density="compact"
                  loadingState={loadingState}
                />
              </Flex>
            </Flex>
          )}

          <Flex alignItems="end" marginStart="auto">
            <ButtonGroup>
              {details.status === PENDING && details.can_approve && (
                <>
                  <Button
                    variant="secondary"
                    UNSAFE_style={{ color: "green" }}
                    onPress={() => {
                      handleApproval([details.wf_id]);
                      dialog.dismiss();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="secondary"
                    UNSAFE_style={{ color: "red" }}
                    onPress={() => setRejectionDialog(true)}
                  >
                    Reject
                  </Button>
                </>
              )}
              <Button variant="secondary" onPress={dialog.dismiss}>
                Close
              </Button>
            </ButtonGroup>
          </Flex>
        </Flex>
      </Content>
      <DialogContainer>
        {rejectionDialog && (
          <AlertDialog
            title="Reject request"
            variant="destructive"
            primaryActionLabel="Yes"
            cancelLabel="No"
            onPrimaryAction={() => {
              handleRejection([details.wf_id], rejectionReason);
              dialog.dismiss();
            }}
            onCancel={() => setRejectionDialog(false)}
            zIndex={2}
          >
            <Flex direction="column" gap="size-150">
              <Text>
                Are you sure you want to reject this request? Please confirm by
                providing reason for rejecting the request.
              </Text>
              <TextArea
                label="Reason"
                width="100%"
                value={rejectionReason}
                onChange={(value) => setRejectionReason(value)}
              />
            </Flex>
          </AlertDialog>
        )}
      </DialogContainer>
    </Dialog>
  );
}

ViewWorkflowDetailsDialog.defaultProps = {
  isManualGrouping: false,
  isAccountUpdate: false,
};

ViewWorkflowDetailsDialog.propTypes = {
  details: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
      PropTypes.objectOf(PropTypes.string),
      PropTypes.any,
    ])
  ).isRequired,
  handleApproval: PropTypes.func.isRequired,
  handleRejection: PropTypes.func.isRequired,
  isManualGrouping: PropTypes.bool,
  isAccountUpdate: PropTypes.bool,
};
