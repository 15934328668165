import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { error as ErrorToast } from "@react/react-spectrum/Toast";
import CamSlidingPane from "../../Common/CamSlidingPane/CamSlidingPane";
import { crossSystemAccountApi } from "../../../api/lookupApi";
import { SourceSystemAccountsLabel } from "../../../constants/SlidingPaneLabels";
import AccountMappingForm from "../../Forms/AccountMappingForm/AccountMappingForm";
import LoadingDialog from "../LoadingDialog";

export default function GetSourceSystemDetailsDialog({
  onClose,
  accountId,
  isCalled,
  open,
}) {
  const [nodeValue, setNodeValue] = useState({});
  const { authState } = useOktaAuth();
  const [isLoadingDialog, setLoadingDialog] = useState(false);

  useEffect(() => {
    if (isCalled && accountId !== undefined) {
      setLoadingDialog(true);
      crossSystemAccountApi
        .fetchAccountMappings({ account_id: accountId }, authState.accessToken)
        .then((data) => {
          setLoadingDialog(false);
          const flattenedData = data[0].source_system_account_list.find(
            (acc) => acc.account_id === accountId
          );
          flattenedData.street = flattenedData.address.street;
          flattenedData.city = flattenedData.address.city;
          flattenedData.state = flattenedData.address.state;
          flattenedData.country = flattenedData.address.country;
          flattenedData.postalcode = flattenedData.address.postalcode;
          setNodeValue({ ...data, ...flattenedData });
        })
        .catch((error) => {
          setLoadingDialog(false);
          console.log(error);
          ErrorToast("Error fetching account details", {
            timeout: 5000,
          });
          setNodeValue([]);
        });
    }
  }, [accountId, isCalled]);

  return (
    <>
      <LoadingDialog isOpen={isLoadingDialog} />
      {open && (
        <CamSlidingPane
          isPaneOpenFlag={open}
          handleOpenDialog={onClose}
          paneTitle="Details"
          Component={
            <AccountMappingForm
              data={nodeValue}
              labels={SourceSystemAccountsLabel()}
            />
          }
        />
      )}
    </>
  );
}

GetSourceSystemDetailsDialog.defaultProps = {
  accountId: "",
};

GetSourceSystemDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isCalled: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};
