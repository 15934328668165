import { Flex, Text } from "@adobe/react-spectrum";
import { useDrag } from "react-dnd";
import PropTypes from "prop-types";
import React from "react";

export default function DraggableComponent({ node }) {
  const [{ isDragging }, drag] = useDrag({
    // type: "orphan",
    item: { node, type: "orphan" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const addressInfo = `${node?.street} ${node?.city}, ${node?.state} ${node?.country}`;

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        border: "3px solid #8fbc8f",
        paddingLeft: "10px",
        color: "black",
      }}
    >
      {/* eslint-disable react/jsx-props-no-spreading */}
      <Flex
        direction="column"
        height="44px"
        {...(addressInfo.length < 60 ? { width: "223px" } : null)}
      >
        <Text UNSAFE_style={{ font: "11.9px adobe-clean", fontWeight: "bold" }}>
          {node?.title}
        </Text>
        <Text UNSAFE_style={{ font: "9.8px adobe-clean" }}>
          {node?.subtitle}
        </Text>
        <Text
          UNSAFE_style={{ font: "9.8px adobe-clean" }}
        >{`${node?.street} ${node?.city}, ${node?.state} ${node?.country}`}</Text>
      </Flex>
    </div>
  );
}

DraggableComponent.propTypes = {
  node: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
};
