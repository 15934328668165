import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Flex,
  Text,
  // SearchField,
  Link as V3Link,
  Heading,
  Button,
  ButtonGroup,
  Tooltip,
  TooltipTrigger,
  useListData,
  ActionButton,
  ComboBox,
  Switch,
  Item,
  useAsyncList,
} from "@adobe/react-spectrum";
import SaveFloppy from "@spectrum-icons/workflow/SaveFloppy";
import InfoOutline from "@spectrum-icons/workflow/InfoOutline";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import {
  error as ErrorToast,
  success as SuccessToast,
} from "@react/react-spectrum/Toast";
// import { AccountMappingPath } from "../../../constants/Routes";
import { SourceSystemAccountsLabel } from "../../../constants/SlidingPaneLabels";
import CamSlidingPane from "../../../components/Common/CamSlidingPane/CamSlidingPane";
import AccountMappingForm from "../../../components/Forms/AccountMappingForm/AccountMappingForm";
import BidirectionalDnDTableView from "./BidirectionalDnDTableView";
import useUserProfile from "../../../context/user-context";
import LoadingDialog from "../../../components/Dialog/LoadingDialog";
import { crossSystemAccountApi } from "../../../api/lookupApi";
import { goldenRecordApi } from "../../../api/goldenRecordApi";
import { hierarchyApi } from "../../../api/hierarchyApi";

export default function ManageAccountGrouping({
  orgAccountInfo,
  sourceSystemAccountInfo,
  returnPage,
}) {
  const columns = [
    { name: "Account ID", id: "account_id" },
    { name: "Source System", id: "source_system" },
    { name: "Account Name", id: "account_name" },
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [slidingPaneData, setSlidingPaneData] = useState({});
  const [slidingPaneLabels, setSlidingPaneLabels] = useState([]);
  const [isPageLoading, setPageLoading] = useState(false);
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();
  const history = useHistory();
  const [searchedId, setSearchedId] = useState("");
  const [newSyncSet, setNewSyncSet] = useState([]);
  const [addedIds, setAddedIds] = useState([]); // list of ids moved from current to new account grouping
  const currentAccountIds = sourceSystemAccountInfo.map((acc) => acc.id); // list of ids in current grouping
  const currentGrouping = useListData({
    initialItems: sourceSystemAccountInfo,
  });

  const [filterByDuns, setFilterByDuns] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const newGrouping = useListData({
    initialItems: [],
  });

  useEffect(() => {
    setAddedIds(
      newGrouping.items
        .filter((acc) => currentAccountIds.includes(acc.id))
        .map((acc) => acc.id)
    );
  }, [newGrouping.items]);

  const targetAccountList = useAsyncList({
    async load({ filterText }) {
      if (!filterText)
        return {
          items: [],
        };
      const json = await hierarchyApi.searchHMS(
        {
          name: filterText,
          hierarchyName: "ECH Hierarchy",
          dunsFilter: filterByDuns,
          resultsSize: 50,
          excludeSourceSystem: true,
        },
        authState.accessToken
      );
      const results = json?.results || [];
      return {
        items: results,
      };
    },
  });

  const createSourceSystem = (sourceSystemList) => {
    sourceSystemList.forEach((account, index) => {
      const sourceSystemAccount = account;
      sourceSystemAccount.index = index;
      const createdDate = new Date(account.createddatetime);
      const modifiedDate = new Date(account.modifieddatetime);
      sourceSystemAccount.sliding_created_at = `${createdDate.toUTCString()} by ${
        account.createdby
      }`;
      sourceSystemAccount.sliding_last_updated = `${modifiedDate.toUTCString()} by ${
        account.modifiedby
      }`;
      const address = account.address[0];
      sourceSystemAccount.address_key = `${
        address.street ? address.street : ""
      } ${address.street_sup ? address.street_sup : ""} ${
        address.city ? address.city : ""
      }, ${address.state ? address.state : ""} ${
        address.country ? address.country : ""
      } ${address.postalcode ? address.postalcode : ""}`;
      sourceSystemAccount.street = address.street ? address.street : "";
      sourceSystemAccount.street_sup = address.street_sup
        ? address.street_sup
        : "";
      sourceSystemAccount.city = address.city ? address.city : "";
      sourceSystemAccount.state = address.state ? address.state : "";
      sourceSystemAccount.postalcode = address.postalcode
        ? address.postalcode
        : "";
      sourceSystemAccount.country = address.country ? address.country : "";
      sourceSystemAccount.id = account.source_system.concat(
        ":",
        account.account_id
      );
    });
    return sourceSystemList;
  };

  const getData = (orgid) => {
    if (orgid === orgAccountInfo.org_entity_id) {
      ErrorToast(
        `Cannot move accounts to the same org id, please try again with a different id`,
        { timeout: 2000 }
      );
    } else {
      setSearchedId(orgid);
      setPageLoading(true);
      crossSystemAccountApi
        .fetchAccountMappings({ org_entity_id: orgid }, authState.accessToken)
        .then((data) => {
          if (data[0]?.source_system_account_list !== null) {
            setNewSyncSet(data[0].source_system_account_list);
            newGrouping.remove(...newSyncSet.map((acc) => acc.id));
            newGrouping.append(
              ...createSourceSystem(data[0].source_system_account_list)
            );
          } else {
            ErrorToast(`${orgid} is an invalid org id, please try again`, {
              timeout: 2000,
            });
          }
          setPageLoading(false);
        })
        .catch((e) => {
          ErrorToast(e.response?.data?.message || "Error fetching org id", {
            timeout: 2000,
          });
          setPageLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedAccountId !== null) getData(selectedAccountId);
  }, [selectedAccountId]);

  const handleOpenDialog = (data) => {
    const label = SourceSystemAccountsLabel();
    setSlidingPaneData(data);
    setSlidingPaneLabels(label);
    setOpenDialog(!openDialog);
  };

  const renderCell = (colKey, data, isNewGroupingTable) => {
    let cellStyle = {};

    if (isNewGroupingTable && currentAccountIds.includes(data.id))
      cellStyle = { color: "green" };
    if (colKey === "account_id") {
      return (
        <Text>
          <V3Link isQuiet onPress={() => handleOpenDialog(data)}>
            {data[colKey]}
          </V3Link>
        </Text>
      );
    }
    return (
      <Text className="prewrap" UNSAFE_style={cellStyle}>
        {data[colKey]}
      </Text>
    );
  };

  const handleRevert = () => {
    setNewSyncSet([]);
    setAddedIds([]);
    setSelectedAccountId(null);
    setSearchedId("");
    newGrouping.remove(...newGrouping.items.map((acc) => acc.id));
    currentGrouping.remove(...sourceSystemAccountInfo.map((acc) => acc.id));
    currentGrouping.append(...sourceSystemAccountInfo);
  };

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      ErrorToast(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      ErrorToast(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      ErrorToast(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  const handleSave = () => {
    const movedAccounts = newGrouping.items.filter((acc) =>
      addedIds.includes(acc.id)
    );
    setPageLoading(true);
    goldenRecordApi
      .modifyAccountGrouping(
        {
          ...(searchedId !== "" && { org_entity_id: searchedId }),
          source_accounts: movedAccounts.map((acc) => ({
            account_id: acc.account_id,
            source_system: acc.source_system,
          })),
          requested_by: user?.userId,
        },
        authState.accessToken
      )
      .then((data) => {
        SuccessToast(
          `Successfully moved accounts to orgid: ${data.org_entity_id}`,
          {
            timeout: 8000,
          }
        );
        setPageLoading(false);
        // history.push(AccountMappingPath, {
        //   searchFilter: history?.location?.state?.searchFilter,
        // });
        history.push(returnPage, {
          searchFilter: history?.location?.state?.searchFilter,
        });
      })
      .catch((e) => {
        handleError(e);
        setPageLoading(false);
      });
    setSearchedId("");
  };

  return (
    <>
      <LoadingDialog isOpen={isPageLoading} />
      <Flex marginTop="size-150" gap="size-150" direction="row">
        <Flex direction="column" width="50%">
          <Heading level={3}>Current Account Grouping</Heading>
          <Flex alignItems="start" height="40px" marginTop="10px">
            <Heading level={4}>ORG ID: {orgAccountInfo.org_entity_id}</Heading>
          </Flex>
          <Flex
            marginTop="size-150"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            height={
              sourceSystemAccountInfo.length > 0
                ? "fit-content"
                : "static-size-4600"
            }
          >
            <BidirectionalDnDTableView
              list={currentGrouping}
              columns={columns}
              aria-label="First TableView in drag between table example"
              renderCell={(colKey, data) => renderCell(colKey, data, false)}
            />
          </Flex>
        </Flex>

        <Flex direction="column" width="50%">
          <Flex>
            <Heading level={3}>
              New Account Grouping {searchedId !== "" ? `: ${searchedId}` : ""}
            </Heading>
            <TooltipTrigger>
              <ActionButton isQuiet aria-label="Information" height="20px">
                <InfoOutline size="S" />
              </ActionButton>
              <Tooltip>
                If no org id is searched a new org id will be created
              </Tooltip>
            </TooltipTrigger>
          </Flex>
          <Flex
            alignItems="baseline"
            gap="size-100"
            height="40px"
            marginTop="10px"
            direction="row"
          >
            <ComboBox
              placeholder="Search by org account name, orgid, or duns"
              menuTrigger="focus"
              onSelectionChange={(val) => {
                setSelectedAccountId(val);
              }}
              inputValue={searchInput}
              onInputChange={(val) => {
                targetAccountList.setFilterText(val);
                setSearchInput(val);
              }}
              items={targetAccountList.items}
              loadingState={targetAccountList.loadingState}
              width="400px"
            >
              {(item) =>
                item !== undefined && (
                  <Item key={item.id}>
                    <Text>{`${item.name} | orgid: ${item.id} | duns: ${
                      item.duns_number === "" ? "null" : item.duns_number
                    }`}</Text>
                  </Item>
                )
              }
            </ComboBox>
            <Flex width="115px">
              <Switch
                isSelected={filterByDuns}
                onChange={setFilterByDuns}
                marginEnd="-8px"
              >
                <Text>DUNS Only</Text>
              </Switch>
            </Flex>
          </Flex>
          <Flex
            marginTop="size-150"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            height={
              sourceSystemAccountInfo.length > 0
                ? "fit-content"
                : "static-size-4600"
            }
          >
            <BidirectionalDnDTableView
              list={newGrouping}
              columns={columns}
              aria-label="First TableView in drag between table example"
              renderCell={(colKey, data) => renderCell(colKey, data, true)}
              disabledKeys={newSyncSet.map((acc) => acc.id)}
            />
          </Flex>
        </Flex>
        {openDialog && (
          <CamSlidingPane
            isPaneOpenFlag={openDialog}
            handleOpenDialog={() => setOpenDialog(false)}
            paneTitle={slidingPaneData.account_name}
            Component={
              <AccountMappingForm
                data={slidingPaneData}
                labels={slidingPaneLabels}
              />
            }
          />
        )}
      </Flex>
      <Flex direction="row-reverse" marginTop="10px" gap="size-125">
        <ButtonGroup UNSAFE_style={{ alignItems: "flex-end" }}>
          <TooltipTrigger>
            <Button
              variant="primary"
              onPress={handleRevert}
              isDisabled={newSyncSet.length === 0 && addedIds.length === 0}
            >
              <Text>Revert</Text>
            </Button>
            <Tooltip>Revert changes</Tooltip>
          </TooltipTrigger>
          <TooltipTrigger>
            <Button
              variant="primary"
              onPress={handleSave}
              isDisabled={addedIds.length === 0}
            >
              <SaveFloppy color="informative" />
              <Text>Save</Text>
            </Button>
            <Tooltip>Save changes</Tooltip>
          </TooltipTrigger>
        </ButtonGroup>
      </Flex>
    </>
  );
}

ManageAccountGrouping.propTypes = {
  orgAccountInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ])
  ).isRequired,
  sourceSystemAccountInfo: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  returnPage: PropTypes.string.isRequired,
};
