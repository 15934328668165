import {
  Flex,
  Item,
  Tabs,
  TabList,
  TabPanels,
  Picker,
  FileTrigger,
  Button,
  TooltipTrigger,
  Tooltip,
  useAsyncList,
  ComboBox,
  Text,
  Switch,
  ListView,
  Heading,
  ActionButton,
  //   SearchField,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import UploadToCloudOutline from "@spectrum-icons/workflow/UploadToCloudOutline";
import RemoveCircle from "@spectrum-icons/workflow/RemoveCircle";
import Download from "@spectrum-icons/workflow/Download";
import { useOktaAuth } from "@okta/okta-react";
import {
  success as SuccesToast,
  error as ErrorToast,
} from "@react/react-spectrum/Toast";
import { hierarchyApi } from "../../../api/hierarchyApi";
import { crossSystemAccountApi } from "../../../api/lookupApi";
import { bulkChangesApi } from "../../../api/bulkChangesApi";

import LoadingDialog from "../../../components/Dialog/LoadingDialog";
import useUserProfile from "../../../context/user-context";

const pickerFileTypes = [
  { id: "ech", name: "ECH" },
  { id: "firmographic", name: "Firmographic" },
];

export default function ImportExport() {
  const [fileType, setFileType] = useState("ech");
  const [filterByDuns, setFilterByDuns] = useState(false);
  const [exportList, setExportList] = useState([]);
  const [firmographicExportPayload, setFirmographicExportPayload] = useState(
    []
  );
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [removeItem, setRemoveItem] = useState();
  const [isPageLoading, setPageLoading] = useState(false);
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();

  useEffect(() => {
    setExportList([]);
    setFirmographicExportPayload([]);
  }, [fileType]);

  useEffect(() => {
    setExportList(exportList.filter((acc) => acc.value !== removeItem.value));
    if (fileType === "firmographic") {
      setFirmographicExportPayload(
        firmographicExportPayload.filter(
          (acc) => acc.formattedData !== removeItem.value
        )
      );
    }
  }, [removeItem]);

  const echTargetAccountList = useAsyncList({
    async load({ filterText }) {
      if (!filterText)
        return {
          items: [],
        };
      const json = await hierarchyApi.searchHMS(
        {
          name: filterText,
          hierarchyName: "ECH Hierarchy",
          dunsFilter: filterByDuns,
          resultsSize: 50,
          searchOnlyToplevel: true,
        },
        authState.accessToken
      );
      const results = json?.results || [];
      return {
        items: results,
      };
    },
  });

  const firmographicTargetAccountList = useAsyncList({
    async load({ filterText }) {
      if (!filterText)
        return {
          items: [],
        };
      const json = await hierarchyApi.searchHMS(
        {
          name: filterText,
          hierarchyName: "ECH Hierarchy",
          dunsFilter: filterByDuns,
          resultsSize: 50,
          excludeSourceSystem: true,
        },
        authState.accessToken
      );
      const results = json?.results || [];
      return {
        items: results,
      };
    },
  });

  // Function to convert Blob to string
  const convertBlobToString = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsText(blob);
    });

  // Function to parse CSV string into array of rows
  const parseCSVString = (csvString) =>
    csvString.split("\n").map((row) => row.split(","));

  // Function to replace commas with another delimiter
  const replaceCommas = (rows, delimiter) =>
    rows.map((row) => row.map((cell) => cell.replace(/,/g, delimiter)));

  // Function to generate CSV content from rows
  const generateCSVContent = (rows) =>
    rows.map((row) => row.join(",")).join("\n");

  const handleExport = (isSample) => {
    const wfType = fileType === "ech" ? "ECH" : "DNB";
    let exportFilePayload;
    if (isSample) {
      exportFilePayload = [];
    } else {
      if (fileType === "ech") {
        const echExportPayload = exportList.map((acc) => ({
          duns_number: acc.value.substring(
            acc.value.indexOf("duns: ") + 6,
            acc.value.length
          ),
          org_entity_id: acc.value.substring(
            acc.value.indexOf("orgid: ") + 7,
            acc.value.indexOf(" | duns")
          ),
        }));
        exportFilePayload = echExportPayload;
      }
      if (fileType === "firmographic") {
        exportFilePayload = firmographicExportPayload.map((org) => ({
          duns_number: org.duns,
          org_entity_id: org.orgid,
        }));
      }
    }

    setPageLoading(true);
    bulkChangesApi
      .exportFile(
        {
          wf_type: wfType,
          export_req_list: exportFilePayload,
        },
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        const fileName = isSample
          ? `${fileType}_sample.csv`
          : `${fileType}_export.csv`;

        // Assuming you have your Blob object named byteArrayBlob
        convertBlobToString(data)
          .then((csvString) => {
            const csvRows = parseCSVString(csvString);
            const modifiedRows = replaceCommas(csvRows, "\t"); // Using tab as delimiter
            const csvContent = generateCSVContent(modifiedRows);

            // Download CSV file
            const url = window.URL.createObjectURL(
              new Blob([csvContent], { type: "text/csv" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            console.error("Error reading Blob:", error);
          });

        // const url = window.URL.createObjectURL(new Blob([csvContent], { type: 'text/csv' }));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", fileName);
        // document.body.appendChild(link);
        // link.click();
      })
      .catch((e) => {
        ErrorToast(
          e.response?.data?.message ||
            "Error importing file(s) please try again",
          { timeout: 2000 }
        );
        setPageLoading(false);
      });
  };

  const handleImport = (importFiles) => {
    const importWfType = fileType === "ech" ? "ECH" : "DUNS";
    const importWfSubType =
      fileType === "ech" ? "hierarchy-user (bulk)" : "firmographic-user (bulk)";

    setPageLoading(true);
    bulkChangesApi
      .importFiles(
        importFiles,
        importWfType,
        importWfSubType,
        user?.userId,
        authState.accessToken
      )

      .then((fileImportStatusList) => {
        setPageLoading(false);
        Object.keys(fileImportStatusList).forEach((file) => {
          const fileStatus = fileImportStatusList[file];
          if (fileStatus.status) {
            SuccesToast(
              `Successfully imported ${file} (wf id: ${fileStatus?.wf_id})`,
              { timeout: 4000 }
            );
          } else {
            ErrorToast(
              `${file} import failed: ${fileStatus.message} (wf id: ${fileStatus?.wf_id})`,
              { timeout: 4000 }
            );
          }
        });
      })
      .catch((e) => {
        ErrorToast(
          e.response?.data?.message ||
            "Error importing file(s) please try again",
          { timeout: 2000 }
        );
        setPageLoading(false);
      });
  };

  const getData = (orgid) => {
    setPageLoading(true);
    crossSystemAccountApi
      .fetchAccountMappings({ org_entity_id: orgid }, authState.accessToken)
      .then((data) => {
        if (data[0]?.org_entity_account !== null) {
          const orgEntityAcc = data[0].org_entity_account;
          const formattedData = `${orgEntityAcc.account_name} | orgid: ${
            orgEntityAcc.org_entity_id
          } | duns: ${orgEntityAcc?.duns_number || "null"}`;
          if (!exportList.map((acc) => acc.value).includes(formattedData)) {
            setExportList([
              ...exportList,
              {
                key: formattedData,
                value: formattedData,
                payload: {
                  duns_number: orgEntityAcc?.duns_number || "null",
                  org_entity_id: orgEntityAcc.org_entity_id,
                },
              },
            ]);
            setFirmographicExportPayload([
              ...firmographicExportPayload,
              {
                orgid: orgEntityAcc.org_entity_id,
                duns: orgEntityAcc?.duns_number || "null",
                formattedData,
              },
            ]);
          }
        } else {
          ErrorToast(`${orgid} is an invalid org id, please try again`, {
            timeout: 2000,
          });
        }
        setPageLoading(false);
      })
      .catch((e) => {
        ErrorToast(e.response?.data?.message || "Error fetching org id", {
          timeout: 2000,
        });
        setPageLoading(false);
      });
  };

  useEffect(() => {
    if (selectedAccountId !== null) getData(selectedAccountId);
  }, [selectedAccountId]);

  const renderFileTypePicker = () => (
    <Picker
      label={
        <Flex height="32px" alignItems="center">
          <Text UNSAFE_style={{ fontSize: "14px" }}>Select file type</Text>
        </Flex>
      }
      onSelectionChange={setFileType}
      items={pickerFileTypes}
      selectedKey={fileType}
    >
      {(item) => <Item>{item.name}</Item>}
    </Picker>
  );

  const renderImportTab = () => (
    <Flex gap="size-150">
      {renderFileTypePicker()}
      <Flex alignItems="end">
        <FileTrigger
          acceptedFileTypes={["text/csv"]}
          allowsMultiple
          onSelect={(e) => {
            const files = Array.from(e);
            handleImport(files);
          }}
        >
          <TooltipTrigger>
            <Button variant="secondary">
              <UploadToCloudOutline />
            </Button>
            <Tooltip>Import files</Tooltip>
          </TooltipTrigger>
        </FileTrigger>
      </Flex>
      <Flex alignItems="end">
        <TooltipTrigger>
          <Button variant="secondary" onPress={() => handleExport(true)}>
            <Download />
          </Button>
          <Tooltip>Download sample file format</Tooltip>
        </TooltipTrigger>
      </Flex>
      {/* )} */}
    </Flex>
  );

  const renderExportTab = () => (
    <Flex direction="column">
      <Flex gap="size-150">
        {renderFileTypePicker()}
        <Flex>
          {fileType === "ech" && (
            <ComboBox
              label={
                <Flex height="32px" alignItems="center">
                  <Flex width="200px">
                    <Text UNSAFE_style={{ fontSize: "14px" }}>
                      Search top level parent accounts
                    </Text>
                  </Flex>
                  <Flex direction="column" alignItems="end" width="200px">
                    <Switch
                      isSelected={filterByDuns}
                      onChange={setFilterByDuns}
                      marginEnd="-8px"
                    >
                      <Text>DUNS Only</Text>
                    </Switch>
                  </Flex>
                </Flex>
              }
              placeholder="Search by top level org account name, org id, or duns id"
              menuTrigger="focus"
              onSelectionChange={(val) => {
                if (!exportList.map((acc) => acc.value).includes(val))
                  setExportList([...exportList, { key: val, value: val }]);
              }}
              onInputChange={(val) => {
                echTargetAccountList.setFilterText(val);
              }}
              items={echTargetAccountList.items}
              loadingState={echTargetAccountList.loadingState}
              width="400px"
            >
              {(item) =>
                item !== undefined && (
                  <Item
                    key={`${item.name} | orgid: ${item.id} | duns: ${
                      item.duns_number === "" ? "null" : item.duns_number
                    }`}
                  >
                    <Text>
                      {`${item.name} | orgid: ${item.id} | duns: ${
                        item.duns_number === "" ? "null" : item.duns_number
                      }`}
                    </Text>
                  </Item>
                )
              }
            </ComboBox>
          )}
          {fileType === "firmographic" && (
            <Flex>
              <ComboBox
                label={
                  <Flex height="32px" alignItems="center">
                    <Flex width="200px">
                      <Text UNSAFE_style={{ fontSize: "14px" }}>
                        Search org accounts
                      </Text>
                    </Flex>
                    <Flex direction="column" alignItems="end" width="200px">
                      <Switch
                        isSelected={filterByDuns}
                        onChange={setFilterByDuns}
                        marginEnd="-8px"
                      >
                        <Text>DUNS Only</Text>
                      </Switch>
                    </Flex>
                  </Flex>
                }
                placeholder="Search by org account name, orgid, or duns"
                menuTrigger="focus"
                onSelectionChange={(val) => {
                  setSelectedAccountId(val);
                }}
                onInputChange={(val) => {
                  firmographicTargetAccountList.setFilterText(val);
                }}
                items={firmographicTargetAccountList.items}
                loadingState={firmographicTargetAccountList.loadingState}
                width="400px"
              >
                {(item) =>
                  item !== undefined && (
                    <Item key={item.id}>
                      <Text>{`${item.name} | ssid: ${item.id} | duns: ${
                        item.duns_number === "" ? "null" : item.duns_number
                      }`}</Text>
                    </Item>
                  )
                }
              </ComboBox>
            </Flex>
          )}
        </Flex>
        {exportList.length > 0 && (
          <Flex alignItems="end">
            <TooltipTrigger>
              <Button variant="secondary" onPress={() => handleExport(false)}>
                <Download />
              </Button>
              <Tooltip>Export data</Tooltip>
            </TooltipTrigger>
          </Flex>
        )}
      </Flex>
      {exportList.length > 0 && (
        <Flex direction="column" marginTop="size-150" marginBottom="10px">
          <Heading level={3}>
            {fileType === "ech"
              ? "Hierarchies to download:"
              : "Org accounts to download:"}
          </Heading>
          <ListView items={exportList} selectionMode="none">
            {(item) => (
              <Item>
                <Flex>
                  <ActionButton
                    aria-label="Icon only"
                    onPress={() => setRemoveItem(item)}
                  >
                    <RemoveCircle color="negative" />
                  </ActionButton>
                  <Flex alignItems="center" marginStart="5px">
                    <span // eslint-disable-line jsx-a11y/no-static-element-interactions
                      style={{ cursor: "text", WebkitUserSelect: "text" }}
                      onPointerDown={(e) => e.stopPropagation()}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <Text>{item.value}</Text>
                    </span>
                  </Flex>
                </Flex>
              </Item>
            )}
          </ListView>
        </Flex>
      )}
    </Flex>
  );

  return (
    <>
      <LoadingDialog isOpen={isPageLoading} />
      <Flex margin="size-300" gap="size-125" direction="column" width="90%">
        <Tabs
          aria-label="import export"
          onSelectionChange={() => {
            setExportList([]);
            setFirmographicExportPayload([]);
          }}
        >
          <TabList>
            <Item key="import">Import</Item>
            <Item key="export">Export</Item>
          </TabList>
          <TabPanels>
            <Item key="import">{renderImportTab()}</Item>
            <Item key="export">{renderExportTab()}</Item>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  );
}
